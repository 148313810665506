export function validateNumericKeypress(e) {
  const allowedKeys = [
    'Backspace',
    'Delete',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ]

  const isNumber = /^[0-9.,]$/.test(e.key)

  if (!isNumber && !allowedKeys.includes(e.key)) {
    e.preventDefault()
  }
}
