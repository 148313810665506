import * as React from 'react'
import * as css from './ChartResults.module.css'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import clsx from 'clsx'

Chart.register(CategoryScale)

export default function ChartGrid({
  title,
  children,
  chart,
  className,
  chartLayout = '1fr 1fr',
}) {
  return (
    <article className={clsx(css.root, className)}>
      <h2 className="h2">{title}</h2>

      <div className={css.grid} style={{ '--chart-layout': chartLayout }}>
        <div className={css.chart}>{chart}</div>

        <div>{children}</div>
      </div>
    </article>
  )
}
