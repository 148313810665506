import * as React from 'react'
import * as css from './Slider.module.css'
import { validateNumericKeypress } from 'utils/input-utils'

export default function Slider({
  label,
  id,
  value,
  onInputChange,
  rangeProps,
  prefix,
  suffix,
  ...props
}) {
  return (
    <div className={css.root}>
      <label className={css.label} htmlFor={id}>
        {label}
      </label>

      <label htmlFor={id} className={css.inputLabel}>
        {prefix && <span>{prefix}</span>}

        <input
          id={id}
          className={css.input}
          type="text"
          pattern="[0-9,.]*"
          value={value}
          onChange={onInputChange}
          onKeyDown={validateNumericKeypress}
          {...props}
        />

        {suffix && <span>{suffix}</span>}
      </label>

      <input
        className={css.slider}
        type="range"
        value={typeof value === 'string' ? value.replace(/,/g, '') : value}
        onChange={(e) => {
          if (typeof e.target.value === 'string') {
            const processedValue = e.target.value.replace(/,/g, '')
            return onInputChange({ target: { value: processedValue } })
          } else {
            return onInputChange(e)
          }
        }}
        {...props}
        {...rangeProps}
      />
    </div>
  )
}
