import * as React from 'react'
import * as css from './CollegeCapital.module.css'
import { calculatorStore, YEARS_BEFORE_COLLEGE_OPTIONS } from './store'
import { FootNote } from './FootNotes'
import { MdPersonAdd, MdPersonRemove } from 'react-icons/md'
import clsx from 'clsx'
import { validateNumericKeypress } from 'utils/input-utils'

export default function CollegeCapital() {
  const { collegeCapital, setCollegeCapital } = calculatorStore()

  function addChild() {
    setCollegeCapital([
      ...collegeCapital,
      {
        id: crypto.randomUUID(),
        cost: 100_000,
        yearsBeforeCollege: YEARS_BEFORE_COLLEGE_OPTIONS[0].value,
      },
    ])
  }

  return (
    <div className={css.root}>
      <div>
        <p>
          <b>Future education costs</b>
        </p>
        <p className={css.description}>
          Estimate the cost of your children’s education. (For reference:
          average costs for a 4-year degree range from $61,499 for public
          schools to $146,210 for private schools)
          <FootNote to="2" />.
        </p>
      </div>

      <div className={css.list}>
        {collegeCapital.map((child) => (
          <Child child={child} key={child.id} />
        ))}
      </div>

      <button
        className={clsx(css.btn, css.add, 'with-icon')}
        onClick={addChild}
      >
        <MdPersonAdd />
        Add a child
      </button>
    </div>
  )
}

function Child({ child: { id, cost, yearsBeforeCollege } }) {
  const { collegeCapital, setCollegeCapital } = calculatorStore()

  function removeChild() {
    setCollegeCapital(collegeCapital.filter((c) => c.id !== id))
  }

  function updateCost(e) {
    setCollegeCapital(
      collegeCapital.map((c) =>
        c.id === id
          ? { ...c, cost: Number(e.target.value.replace(/,/g, '')) }
          : c,
      ),
    )
  }

  function updateYearsBeforeCollege(e) {
    setCollegeCapital(
      collegeCapital.map((c) =>
        c.id === id ? { ...c, yearsBeforeCollege: e.target.value } : c,
      ),
    )
  }

  return (
    <div className={clsx(css.child, 'anim-fade-to-r')} key={id}>
      <div className={css.header} />

      <label>
        <div className={css.label}>Estimated 4-year cost</div>
        <div className={css.value}>
          <span>$</span>
          <input
            className="input"
            value={cost.toLocaleString()}
            pattern="[0-9,.]*"
            onChange={updateCost}
            onKeyDown={validateNumericKeypress}
            type="text"
          />
        </div>
      </label>

      <label>
        <div className={css.label}>Years before college</div>
        <select
          className="input"
          value={yearsBeforeCollege}
          onChange={updateYearsBeforeCollege}
        >
          {YEARS_BEFORE_COLLEGE_OPTIONS.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>

      <button
        className={clsx(css.btn, css.remove, 'with-icon')}
        onClick={removeChild}
        title="Remove this child"
      >
        <MdPersonRemove />
      </button>
    </div>
  )
}
