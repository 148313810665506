import * as React from 'react'
import * as css from './Team.module.css'
import Employee from 'components/employee/Employee'

const Team = ({ title, team, employees }) => {
  return (
    <section className="container padding">
      {title && (
        <header className={css.header}>
          <h2 className="uppercase">{title}</h2>
        </header>
      )}

      <ul className={css.list}>
        {(team?.employees || employees)?.map((employee, key) => (
          <Employee {...employee} key={key} />
        ))}
      </ul>
    </section>
  )
}

export default Team
