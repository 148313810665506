import * as React from 'react'
import * as css from './Inputs.module.css'
import { validateNumericKeypress } from 'utils/input-utils'
import clsx from 'clsx'

export function Input({
  label,
  description,
  prefix,
  inline,

  // input
  value,
  setter,

  // select
  options,

  // output
  output,
  hideTooltip,

  ...props
}) {
  const isOutput = typeof output === 'number'

  return (
    <label className={clsx(css.root, inline && css.inline)}>
      <div>
        <p>
          <b>{label}</b>

          {isOutput && !hideTooltip && (
            <sup
              className={css.tooltip}
              title="Automatically calculated based on your inputs."
            >
              i
            </sup>
          )}
        </p>

        {description && <div className={css.description}>{description}</div>}
      </div>

      <div className="value">
        {prefix && <span>{prefix}</span>}

        {value >= 0 && (
          <input
            className="input"
            type="text"
            value={value.toLocaleString()}
            pattern="[0-9,.]*"
            onKeyDown={validateNumericKeypress}
            onChange={(e) => {
              const value = e.target.value.replace(/,/g, '')
              setter(Number(value))
            }}
            {...props}
          />
        )}

        {options && (
          <select
            className="input"
            onChange={(e) => {
              setter(Number(e.target.value))
            }}
          >
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}

        {isOutput && (
          <output className="anim-fade" key={output}>
            {output === 0
              ? '0'
              : output.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </output>
        )}
      </div>
    </label>
  )
}
