import * as React from 'react'
import * as css from './Employee.module.css'
import { Link } from 'gatsby'
import EmployeePhoto from 'components/employee/EmployeePhoto'
import EmployeeContact from 'components/employee/EmployeeContact'

const Employee = ({ name, title, designation, contact, image, seo }) => {
  const url = seo && `/our-team#${ seo.slug.current }`

  return !!url ? (
    <div className={css.root}>
      <Link to={url} className={css.url}>
        <EmployeePhoto image={image} name={name} />
      </Link>

      <div className={css.info}>
        <Link to={url} className={`${css.title} h2`}>
          {[name, designation].filter(Boolean).join`, `}
        </Link>

        {title && <div className="uppercase">{title}</div>}

        <EmployeeContact {...contact} />
      </div>
    </div>
  ) : null
}

export default Employee
