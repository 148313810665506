import { create } from 'zustand'

export const YEARS_INCOME_NEEDED_OPTIONS = [
  { label: '10 years', value: 8.8 },
  { label: '15 years', value: 12.4 },
  { label: '20 years', value: 15.4 },
  { label: '25 years', value: 18.1 },
  { label: '30 years', value: 20.4 },
  { label: '35 years', value: 22.4 },
  { label: '40 years', value: 24.1 },
]

export const YEARS_BEFORE_COLLEGE_OPTIONS = [
  { label: '5 years', value: 0.95 },
  { label: '10 years', value: 0.91 },
  { label: '15 years', value: 0.86 },
  { label: '20 years', value: 0.82 },
]

export const calculatorStore = create((set) => ({
  // income

  totalAnnualIncome: 0, // 112_500
  setTotalAnnualIncome: (totalAnnualIncome) => set({ totalAnnualIncome }),

  otherAnnualIncome: 0, // 75_000
  setOtherAnnualIncome: (otherAnnualIncome) => set({ otherAnnualIncome }),

  yearsIncomeNeeded: YEARS_INCOME_NEEDED_OPTIONS[0].value,
  setYearsIncomeNeeded: (yearsIncomeNeeded) => set({ yearsIncomeNeeded }),

  // expenses

  funeralExpenses: 0, // 20_000
  setFuneralExpenses: (funeralExpenses) => set({ funeralExpenses }),

  outstandingDebt: 0, // 500_000
  setOutstandingDebt: (outstandingDebt) => set({ outstandingDebt }),

  collegeCapital: [],
  setCollegeCapital: (collegeCapital) => set({ collegeCapital }),

  // assets

  savingsAndInvestments: 0, // 100_000
  setSavingsAndInvestments: (savingsAndInvestments) =>
    set({ savingsAndInvestments }),

  retirementSavings: 0, // 400_000
  setRetirementSavings: (retirementSavings) => set({ retirementSavings }),

  existingLifeInsurance: 0, // 500_000
  setExistingLifeInsurance: (existingLifeInsurance) =>
    set({ existingLifeInsurance }),

  // results

  lifeInsuranceNeeded: 0,
  setLifeInsuranceNeeded: (lifeInsuranceNeeded) => set({ lifeInsuranceNeeded }),

  // actions

  reset: () =>
    set({
      totalAnnualIncome: 0,
      otherAnnualIncome: 0,
      yearsIncomeNeeded: YEARS_INCOME_NEEDED_OPTIONS[0].value,
      funeralExpenses: 0,
      outstandingDebt: 0,
      collegeCapital: [],
      savingsAndInvestments: 0,
      retirementSavings: 0,
      existingLifeInsurance: 0,
    }),
}))

export function getReportUrl() {
  const {
    totalAnnualIncome,
    otherAnnualIncome,
    yearsIncomeNeeded,
    funeralExpenses,
    outstandingDebt,
    collegeCapital,
    savingsAndInvestments,
    retirementSavings,
    existingLifeInsurance,
  } = calculatorStore()

  if (typeof window === 'undefined') return

  const url = new URL(window.location.href)

  totalAnnualIncome && url.searchParams.set('tai', totalAnnualIncome)
  otherAnnualIncome && url.searchParams.set('oai', otherAnnualIncome)
  yearsIncomeNeeded && url.searchParams.set('yin', yearsIncomeNeeded)
  funeralExpenses && url.searchParams.set('fe', funeralExpenses)
  outstandingDebt && url.searchParams.set('od', outstandingDebt)

  !!collegeCapital?.length &&
    url.searchParams.set('cc', JSON.stringify(collegeCapital))

  savingsAndInvestments && url.searchParams.set('si', savingsAndInvestments)
  retirementSavings && url.searchParams.set('rs', retirementSavings)
  existingLifeInsurance && url.searchParams.set('eli', existingLifeInsurance)

  return url.toString()
}
