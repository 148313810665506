import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

const ImageWithCaption = ({ image, alt, caption, className }) => (
  <figure className={clsx(className, 'richtext')}>
    {image && (
      <GatsbyImage
        image={getImage(image.asset)}
        alt={alt || ''}
      />
    )}
    {caption && <figcaption>{caption}</figcaption>}
  </figure>
)

export default ImageWithCaption
