import * as React from 'react'
import * as css from './TaxEquivalentCalculator.module.css'
import { calculatorStore } from './store'
import ManualInputs from './ManualInputs'
import TaxabaleEquiavalentChart from './TaxabaleEquiavalentChart'
import TaxDeferralChart from './TaxDeferralChart'
import TableResults from './TableResults'
import CopyReportLink from './CopyReportLink'
import clsx from 'clsx'

export default function TaxEquivalentCalculator(props) {
  const {
    deposit,
    setDeposit,
    annuityRate,
    setAnnuityRate,
    taxRate,
    setTaxRate,
    term,
    setTerm,
  } = calculatorStore()

  React.useEffect(() => {
    function readUrlParams() {
      const params = new URLSearchParams(window.location.search)
      const depositParam = params.get('deposit')
      const annuityRateParam = params.get('annuityRate')
      const taxRateParam = params.get('taxRate')
      const termParam = params.get('term')

      if (depositParam) setDeposit(Number(depositParam))
      if (annuityRateParam) setAnnuityRate(Number(annuityRateParam))
      if (taxRateParam) setTaxRate(Number(taxRateParam))
      if (termParam) setTerm(Number(termParam))
    }

    readUrlParams()
  }, [])

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    function writeUrlParams() {
      const params = new URLSearchParams()
      params.set('deposit', deposit)
      params.set('annuityRate', annuityRate)
      params.set('taxRate', taxRate)
      params.set('term', term)

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params.toString()}`,
      )
    }

    writeUrlParams()
  }, [deposit, annuityRate, taxRate, term])

  return (
    <section className={clsx(css.root, 'padding container')}>
      <ManualInputs />
      <TaxabaleEquiavalentChart />
      <TaxDeferralChart {...props} />

      <article className="text-center">
        <CopyReportLink />
      </article>

      <TableResults />

      <p>
        <small>
          *Tax deferred build up is taxable upon withdrawal. CPS and its
          affiliates do not provide tax, legal or accounting advice. You should
          always consult your own tax, legal and accounting advisors before
          engaging in any transaction. This material has been prepared for
          informational purposes only. Any misrepresentation of the information
          is strictly prohibited.
        </small>
      </p>
    </section>
  )
}
