import * as React from 'react'
import * as css from './TermSelection.module.css'
import { calculatorStore, TERMS } from './store'

export default function TermSelection() {
  const { term, setTerm } = calculatorStore()

  return (
    <label className={css.root}>
      <span className={css.label}>Term Length</span>

      <select
        className={css.select}
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      >
        {TERMS.map((term) => (
          <option key={term} value={term}>
            {term} years
          </option>
        ))}
      </select>
    </label>
  )
}
