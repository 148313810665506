import * as React from 'react'

const Jotform = ({ title, iframe }) => (
  <section className="container padding">
    {title && <h2 className="h2">{title}</h2>}
    <div
      style={{ marginTop: '2rem' }}
      dangerouslySetInnerHTML={{
        __html: iframe.replace('scrolling="no"', 'scrolling="yes"')
      }}
    />
  </section>
)

export default Jotform
