import * as React from 'react'
import * as css from './EmployeeContact.module.css'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

const EmployeeContact = ({ tel, email }) => (
  <dl className={css.root}>
    {tel && <>
      <dt><FaPhone className={css.icon} /></dt>
      <dd><a href={`tel:${tel}`}>{tel}</a></dd>
    </>}

    {email && <>
      <dt><FaEnvelope className={css.icon} /></dt>
      <dd><a href={`mailto:${email}`}>{email}</a></dd>
    </>}
  </dl>
)

export default EmployeeContact
