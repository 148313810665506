import * as React from 'react'
import * as css from './FootNotes.module.css'

const footnotes = [
  'Social Security benefits are not included in this estimate and should be reviewed separately.',
  'College cost estimates are based on historical data and may vary.',
  'Retirement savings distributions may be subject to income tax and should be factored into your overall financial planning.',
]

export function FootNotesList() {
  return (
    <footer className={css.root}>
      <ul>
        {footnotes.map((footnote, i) => (
          <li id={`footnote-${i + 1}`} key={i}>
            <sup>
              <a className="link" href={`#footnote-${i + 1}-ref`}>
                {i + 1}
              </a>
            </sup>

            {footnote}
          </li>
        ))}
      </ul>
    </footer>
  )
}

export function FootNote({ to }) {
  return (
    <sup>
      <a id={`footnote-${to}-ref`} className="link" href={`#footnote-${to}`}>
        {to}
      </a>
    </sup>
  )
}
