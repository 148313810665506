// extracted by mini-css-extract-plugin
export var add = "CollegeCapital-module--add--LH+yf";
export var btn = "CollegeCapital-module--btn--kYUmj";
export var child = "CollegeCapital-module--child--+f763";
export var description = "CollegeCapital-module--description--xJgFm";
export var header = "CollegeCapital-module--header--Gv8Y0";
export var label = "CollegeCapital-module--label--KQriL";
export var list = "CollegeCapital-module--list--g7ahl";
export var remove = "CollegeCapital-module--remove--bVIDA";
export var root = "CollegeCapital-module--root--dLFns";
export var value = "CollegeCapital-module--value--kFTcs";