import * as React from 'react'
import * as css from './LifeInsuranceCalculator.module.css'
import { calculatorStore } from './store'
import SanityBlock from 'components/common/SanityBlock'
import Actions from './Actions'
import Fields from './Fields'
import clsx from 'clsx'
import { Input } from './Inputs'
import { FootNotesList } from './FootNotes'

export default function LifeInsuranceCalculator({ _rawContent }) {
  const {
    lifeInsuranceNeeded,
    setTotalAnnualIncome,
    setOtherAnnualIncome,
    setYearsIncomeNeeded,
    setFuneralExpenses,
    setOutstandingDebt,
    setCollegeCapital,
    setSavingsAndInvestments,
    setRetirementSavings,
    setExistingLifeInsurance,
  } = calculatorStore()

  React.useEffect(() => {
    function readUrlParams() {
      const params = new URLSearchParams(window.location.search)

      const tai = params.get('tai')
      const oai = params.get('oai')
      const yin = params.get('yin')
      const fe = params.get('fe')
      const od = params.get('od')
      const cc = params.get('cc')
      const si = params.get('si')
      const rs = params.get('rs')
      const eli = params.get('eli')

      if (tai) setTotalAnnualIncome(Number(tai))
      if (oai) setOtherAnnualIncome(Number(oai))
      if (yin) setYearsIncomeNeeded(Number(yin))
      if (fe) setFuneralExpenses(Number(fe))
      if (od) setOutstandingDebt(Number(od))
      if (cc) setCollegeCapital(JSON.parse(cc))
      if (si) setSavingsAndInvestments(Number(si))
      if (rs) setRetirementSavings(Number(rs))
      if (eli) setExistingLifeInsurance(Number(eli))
    }

    readUrlParams()
  }, [])

  return (
    <div className={clsx(css.root, 'padding container')}>
      {_rawContent && (
        <header className="richtext">
          <SanityBlock body={_rawContent} />
        </header>
      )}

      <div className={css.layout}>
        <div className={css.main}>
          <Actions />
          <Fields />
        </div>

        <div className={css.results}>
          <Input
            label="Your Life Insurance Estimate"
            description={
              <p className="tablet-and-up">
                Based on your inputs, your estimated amount of coverage needed
                to support your family is:
              </p>
            }
            hideTooltip
            prefix="$"
            output={lifeInsuranceNeeded}
          />
        </div>
      </div>

      <FootNotesList />
    </div>
  )
}
