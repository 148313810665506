import * as React from 'react'
import { calculatorStore, formatMoney, formatPercentage } from './store'

export default function AllTables() {
  const { term, deposit, annuityRate, taxRate } = calculatorStore()

  function getStartAndEndValues(year, rate = annuityRate / 100) {
    const start = year === 0 ? deposit : deposit * Math.pow(1 + rate, year)
    const end = start * (1 + rate)
    return { start, end }
  }

  function getValuesWithoutTaxDeferral(start = deposit) {
    const taxableGain = (start * annuityRate) / 100
    const taxesPaid = (taxableGain * taxRate) / 100
    const netGain = taxableGain - taxesPaid
    const netGainPct = netGain / start

    return {
      taxableGain,
      taxesPaid,
      netGain,
      netGainPct,
    }
  }

  return (
    <div className="all-tables anim-fade-to-b">
      <h2 className="h2">Tax-Deferred Annuity</h2>
      <table>
        <tbody>
          <tr>
            <th>Year</th>
            <th>Account Value</th>
            <th>Annuity Rate</th>
            <th>Year End Account Value</th>
          </tr>
          {Array.from({ length: term }).map((_, i) => {
            const { start, end } = getStartAndEndValues(i)
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td id={i === 0 ? 'TDAG_i' : undefined} data-value={start}>
                  {formatMoney(start)}
                </td>
                <td>{formatPercentage(annuityRate)}</td>
                <td id={i === term - 1 ? 'TDAG_f' : undefined} data-value={end}>
                  {formatMoney(end)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <h2 className="h2">Taxable Yield Required for Equivalent Return</h2>
      <table>
        <tbody>
          <tr>
            <th>Year</th>
            <th>Account Value</th>
            <th>Required Taxable Yield</th>
            <th>Gain</th>
            <th>Tax</th>
            <th>Taxes Paid</th>
            <th>Net Gain</th>
            <th>Net % Gain</th>
            <th>Year End Account Value</th>
          </tr>
          {Array.from({ length: term }).map((_, i) => {
            const { start, end } = getStartAndEndValues(i)
            const taxableYield = annuityRate / (1 - taxRate / 100)
            const gain = (start * taxableYield) / 100
            const netGain = gain - (gain * taxRate) / 100
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{formatMoney(start)}</td>
                <td id={i === 0 ? 'TEY' : undefined} data-value={taxableYield}>
                  {formatPercentage(taxableYield)}
                </td>
                <td>{formatMoney(gain)}</td>
                <td>{formatPercentage(taxRate, 0)}</td>
                <td>{formatMoney((gain * taxRate) / 100)}</td>
                <td>{formatMoney(netGain)}</td>
                <td>{formatPercentage((netGain / start) * 100)}</td>
                <td>{formatMoney(end)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <h2 className="h2">Return Without Tax Deferral</h2>
      <table>
        <tbody>
          <tr>
            <th>Year</th>
            <th>Account Value</th>
            <th>Taxable Return %</th>
            <th>Taxable Gain</th>
            <th>Tax</th>
            <th>Taxes Paid</th>
            <th>Net Gain</th>
            <th>Net % Gain</th>
            <th>Year End Account Value</th>
          </tr>
          {Array.from({ length: term }).map((_, i) => {
            const { start, end } = getStartAndEndValues(
              i,
              getValuesWithoutTaxDeferral().netGainPct,
            )
            const { taxableGain, taxesPaid, netGain, netGainPct } =
              getValuesWithoutTaxDeferral(start)
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{formatMoney(start)}</td>
                <td>{formatPercentage(annuityRate)}</td>
                <td>{formatMoney(taxableGain)}</td>
                <td>{formatPercentage(taxRate, 0)}</td>
                <td>{formatMoney(taxesPaid)}</td>
                <td className="net-gain" data-value={netGain}>
                  {formatMoney(netGain)}
                </td>
                <td>{formatPercentage(netGainPct * 100)}</td>
                <td>{formatMoney(end)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
