import * as React from 'react'
import * as css from './Actions.module.css'
import { calculatorStore, getReportUrl } from './store'
import { MdOutlineCancelPresentation, MdLink } from 'react-icons/md'
import { IoMdCheckmark } from 'react-icons/io'

export default function Actions() {
  const { reset } = calculatorStore()
  const reportUrl = getReportUrl()
  const [isCopied, setIsCopied] = React.useState(false)

  function clear() {
    if (typeof window === 'undefined') return

    if (window.confirm('Are you sure you want to clear all fields?')) {
      reset()
      window.history.replaceState({}, '', window.location.pathname)
    }
  }

  function copyReport() {
    if (typeof window === 'undefined') return

    navigator.clipboard.writeText(reportUrl)

    setIsCopied(true)

    const timeout = setTimeout(() => {
      setIsCopied(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }

  return (
    <nav className={css.root}>
      <button className="button with-icon" onClick={clear}>
        <MdOutlineCancelPresentation />
        Clear all fields
      </button>

      <button className="button with-icon" onClick={copyReport}>
        {isCopied ? (
          <>
            <IoMdCheckmark />
            Copied report link!
          </>
        ) : (
          <>
            <MdLink />
            Copy report link
          </>
        )}
      </button>
    </nav>
  )
}
