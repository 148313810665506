import * as React from 'react'
import * as css from './EmployeePhoto.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

const EmployeePhoto = ({ image, name, className }) => (
  <div className={clsx(className, css.root, !image && css.placeholder)}>
    {image && (
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={name}
      />
    )}
  </div>
)

export default EmployeePhoto
