import * as React from 'react'
import * as css from './Fields.module.css'
import { calculatorStore, YEARS_INCOME_NEEDED_OPTIONS } from './store'
import { Input } from './Inputs'
import { FootNote } from './FootNotes'
import CollegeCapital from './CollegeCapital'

export default function Fields() {
  const {
    // income
    totalAnnualIncome,
    setTotalAnnualIncome,
    otherAnnualIncome,
    setOtherAnnualIncome,
    yearsIncomeNeeded,
    setYearsIncomeNeeded,
    // expenses
    funeralExpenses,
    setFuneralExpenses,
    outstandingDebt,
    setOutstandingDebt,
    collegeCapital,
    // assets
    savingsAndInvestments,
    setSavingsAndInvestments,
    retirementSavings,
    setRetirementSavings,
    existingLifeInsurance,
    setExistingLifeInsurance,
    // results
    setLifeInsuranceNeeded,
  } = calculatorStore()

  const capitalNeededForIncome =
    (totalAnnualIncome - otherAnnualIncome) * yearsIncomeNeeded

  const totalCollegeCapital =
    collegeCapital?.reduce(
      (acc, curr) => acc + curr.cost * Number(curr.yearsBeforeCollege),
      0,
    ) || 0

  const totalCapitalRequired = [
    capitalNeededForIncome,
    funeralExpenses,
    outstandingDebt,
    totalCollegeCapital,
  ]
    .filter(Number)
    .reduce((acc, curr) => acc + curr, 0)

  const totalIncomeProducingAssets =
    savingsAndInvestments + retirementSavings + existingLifeInsurance

  React.useEffect(() => {
    setLifeInsuranceNeeded(totalCapitalRequired - totalIncomeProducingAssets)
  }, [totalCapitalRequired, totalIncomeProducingAssets, setLifeInsuranceNeeded])

  return (
    <section className={css.root}>
      <fieldset>
        <legend>Income</legend>
        <Input
          label="Annual income needed to maintain your family's current lifestyle"
          description={
            <p>
              Enter the total yearly amount your family would require before
              taxes to cover expenses (typically 60%–75% of your current
              income).
            </p>
          }
          prefix="$"
          value={totalAnnualIncome}
          setter={setTotalAnnualIncome}
        />
        <Input
          label="Income from other sources"
          description={
            <p>
              Include any other regular income your family would receive, such
              as your spouse's earnings or fixed pensions <FootNote to="1" />.
              Exclude asset income—it’s accounted for later.
            </p>
          }
          prefix="$"
          value={otherAnnualIncome}
          setter={setOtherAnnualIncome}
        />
        <Input
          label="Income to replace"
          prefix="$"
          output={totalAnnualIncome - otherAnnualIncome}
          inline
        />
        <Input
          label="Number of years to replace income"
          description={<p>Select how long you want this income to last.</p>}
          options={YEARS_INCOME_NEEDED_OPTIONS}
          setter={setYearsIncomeNeeded}
          inline
        />
        <Input
          label="Total income capital required"
          description={
            <p>Automatically calculated based on the above details.</p>
          }
          prefix="$"
          output={capitalNeededForIncome}
          inline
        />
      </fieldset>

      <fieldset>
        <legend>Expenses</legend>
        <Input
          label="Final expenses"
          description={
            <p>
              Enter an estimate for funeral and related costs. Typical costs are
              $15,000 or 4% of your estate.
            </p>
          }
          prefix="$"
          value={funeralExpenses}
          setter={setFuneralExpenses}
        />
        <Input
          label="Outstanding debts"
          description={
            <p>
              Add the remaining balance of your mortgage, car loans, credit
              cards, or other debts.
            </p>
          }
          prefix="$"
          value={outstandingDebt}
          setter={setOutstandingDebt}
        />

        <CollegeCapital />

        <Input
          label="Total capital required"
          prefix="$"
          output={totalCapitalRequired}
          inline
        />
      </fieldset>

      <fieldset>
        <legend>Assets</legend>
        <Input
          label="Savings and investments"
          description={
            <p>
              Include the total value of your liquid assets such as bank
              accounts, CDs, stocks, and mutual funds.
            </p>
          }
          prefix="$"
          value={savingsAndInvestments}
          setter={setSavingsAndInvestments}
        />
        <Input
          label="Retirement savings"
          description={
            <p>
              Enter the current value of your retirement accounts (e.g., 401(k),
              IRA, pension plans)
              <FootNote to="3" />.
            </p>
          }
          prefix="$"
          value={retirementSavings}
          setter={setRetirementSavings}
        />
        <Input
          label="Existing life insurance"
          description={
            <p>
              Include all current coverage, including employer-provided group
              insurance and policies you own personally.
            </p>
          }
          prefix="$"
          value={existingLifeInsurance}
          setter={setExistingLifeInsurance}
        />
        <Input
          label="Total income producing assets"
          prefix="$"
          output={totalIncomeProducingAssets}
          inline
        />
      </fieldset>
    </section>
  )
}
