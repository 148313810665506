import * as React from 'react'
import SanityBlock from 'components/common/SanityBlock'

const Richtext = ({ _rawDescription, className }) => (
  <section className={className}>
    <SanityBlock body={_rawDescription} />
  </section>
)

export default Richtext
